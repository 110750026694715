import React from 'react'
import styles from "./Section1.module.css";
import heroImg from "../assets/svg/wws.png";
import rect from "../assets/img/rect.png";


const Section1 = () => {
  
  return (
    <>
        <div className={styles.s1}>
        <div className={styles.frontimg}>
            <img src={rect} alt="pm" />
          </div>
          <div className={styles.s1left}>
            <div>
            <div>
            <img src={heroImg} alt="pm" />
            </div>
            </div>
          </div>
          <div className={styles.s1right}>
          <div className={styles.txt}>
          <p>MEMBERSHIP</p>
          <p>BUILD YOUR STORE WITH US</p>
          <p>
            CHECKOUT OUT OUR <a>New Plans</a>
          </p>
          <button className={styles.btn}>PICK A PLAN</button>
        </div>
          </div>
        </div>
    </>
  )
}

export default Section1

