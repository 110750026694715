import multicar1 from "../assets/multicar1.png";
import multicar2 from "../assets/multicar2.png";
import multicar3 from "../assets/multicar3.png";
import multicar4 from "../assets/multicar4.png";
import multicar5 from "../assets/multicar5.png";
import multicar6 from "../assets/multicar6.png";
import multicar7 from "../assets/multicar7.png";
import multicar8 from "../assets/multicar8.png";
import multicar9 from "../assets/multicar9.png";
import multicar10 from "../assets/multicar10.png";
import multicar11 from "../assets/multicar11.png";

export const multiData = [
  multicar1,
  multicar10,
  multicar2,
  multicar3,
  multicar11,
  multicar4,
  multicar5,
  multicar9,
  multicar6,
  multicar7,
  multicar8,
];
