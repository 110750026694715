import React from 'react'
import styles from './Banner.module.css'
import pm from "../assets/svg/pm.png";
import Bi from "../assets/svg/bi.png";
import store from "../assets/svg/store.png";
import play from "../assets/play.png";

const Banner = () => {
  return (
    <div className={styles.maindiv}>

        <img  src={Bi} alt='pm' className={styles.pim}/>

      <div className={styles.div3} >

        <h1 style={{fontWeight:'700'}}>UPTO 50% OFF</h1>
        <h4>FREE SHIPPING & MORE KNOCKOUT OFFERS!!</h4>

        <div className={styles.div31}>
           <img src={store} style={{width:'20px'}} alt='pm'/>
           <h5 style={{marginTop:'6px',marginLeft:'5px'}}>FAISHON HOUSE</h5>
        </div>
        
        <div className={styles.div32}>
        <h5 style={{marginTop:'6px',marginRight:'25px'}}>Download PM App </h5>
           <img src={play} alt='pm'/>
        </div>
          
      </div>
    </div>
  )
}

export default Banner