import React from 'react'
import Section4 from '../components/Section4'

const AboutUs = () => {
    return (
      <>
      <Section4/>
      </>
    )
  }
  
  export default AboutUs