import React from "react";
import styles from "./Footer.module.css";
import {
  AiFillMail,
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineYoutube,
  AiTwotonePhone,
} from "react-icons/ai";
import { RxTwitterLogo } from "react-icons/rx";
import { ImLocation } from "react-icons/im";
import { AiOutlineCopyright } from "react-icons/ai";

const Footer = () => {
  return (
    <div className={styles.FooterContainer}>
      <div className={styles.FooterWrapper}>
        <div className={styles.FooterSection}>
          <div className={styles.FooterHeading}>QUICK LINKS</div>
          <ul>
            <li>
              <a href="/" aria-label="home" >Home</a>
            </li>
            <li>
              <a href="/about" aria-label="home" >About Us</a>
            </li>
            <li>
              <a href="/blogs" aria-label="home" >Blogs</a>
            </li>
            <li>
              <a href="/join" aria-label="home" >Join us</a>
            </li>
            <li>
              <a href="/login" aria-label="home" >Sign Up</a>
            </li>
          </ul>
        </div>
        <div className={styles.FooterSection}>
          <div className={styles.FooterHeading}>COMPANY</div>
          <ul>
            <li>
              <a href="/story" aria-label="home" >Story</a>
            </li>
            <li>
              <a href="/impact" aria-label="home" >Impact</a>
            </li>
            <li>
              <a href="/vision" aria-label="home" >Vision</a>
            </li>
            <li>
              <a href="/community" aria-label="home" >Community</a>
            </li>
          </ul>
        </div>

        <div className={styles.FooterSection}>
          <div className={styles.FooterHeading}>VISIT OUR BLOG</div>
          <ul>
            <li>
              <a href="/blogs" aria-label="home" >
                Jigyasa
              </a>{" "}
              by PM
            </li>
          </ul>
          <div className={styles.FooterHeading1}>FOLLOW US ON</div>
          <ul className={styles.SocialIcons}>
          <li>
            <a href="https://www.instagram.com/pandrimarket.raipur" aria-label="home" >
              <AiOutlineInstagram size={30}  />
            </a>
            </li>
          <li>
            <a href="https://facebook.com/pandrimarket.raipur" aria-label="home" >
              <AiOutlineFacebook size={30} />
            </a>
            </li>
          <li>
            <a href="https://youtube.com/@pandrimarket" aria-label="home" >
              <AiOutlineYoutube size={30}  />
            </a>
            </li>
          <li>
            <a href="https://twitter.com/PandriMarket" aria-label="home" >
              <RxTwitterLogo size={30}  />
            </a>
            </li>
          </ul>
        </div>
        <div className={styles.FooterSection}>
          <div className={styles.FooterHeading}>CONTACT US</div>
          <ul className={styles.ContactInfo}>
           <li> <a href="tel:+919244092424" aria-label="home" >
             
                <AiTwotonePhone size={25} />
             
            </a> </li>
            <li><a href="mailto:admin@pandrimarket.com" aria-label="home" >
             
                <AiFillMail size={25} />
             
            </a></li>
            <li><a href="https://goo.gl/maps/GY2FSKUJaiesTwnk9" aria-label="home" >
              
                <ImLocation size={25}/>
              
            </a></li>

          </ul>
        </div>
      </div>
      <div className="w-full group">
      <div className="max-w-container mx-auto pt-5 pb-3">
        <p className="text-titleFont font-normal text-center flex md:items-center justify-center text-primeColor duration-200 text-sm">
          <span className="text-md mr-[1px] mt-[2px] md:mt-0 text-center hidden md:inline-flex">
            <AiOutlineCopyright />
          </span>
          Copyright 2023 | Pandri Market |
          <a href="/terms&conditions" target="_blank" rel="noreferrer" aria-label="home" >
            <span className="ml-1 font-medium group-hover:text-primeColor">
              Terms & Conditions
            </span>
          </a>
        </p>
      </div>
    </div>
    </div>
  );
};

export default Footer;
