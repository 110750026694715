import React from 'react'
import NavHom from '../components/NavHom'
import Footer from '../components/Footer'
import Hero1 from '../components/Hero2'


const Home = () => {
  return (
    <>
      <Hero1 />
    </>
  )
}

export default Home