import React from 'react'
import styles from './Button1.module.css'

const Button1 = () => {
  return (
    <>
    <div className={styles.Button1Container}>
    <button>
        SEE MORE BLOGS
    </button>
    </div>
    </>
  )
}

export default Button1