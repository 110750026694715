import React from 'react'
import Hero1 from '../components/Hero1'


const Home = () => {
    return (
      <>
      <Hero1 />
      </>
    )
  }
  
  export default Home