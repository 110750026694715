import {
    spfFive,
    spf6,
    spf7,
    spf8,
    spf9,
    spf10,
    spf11,
    spf12,
    spf13,
    spf14,
    spf15,
    spf16,
    spf17,
    spf18,
    spf19,
    spf20,
    spf21,
    spf22,
  } from "../assets/images/index";
  // =================== Special Offer data End here ==============
  
  // =================== PaginationItems Start here ===============
  
  export const paginationItems = [
    {
      _id: 1001,
      img: spf6,
      productName: "Women's Overcoat",
      price: "1435",
      mprice: "1935",
      off: "26",
      review: "135",
      shop:"Muklava",
      color: "Peach, Light Pink",
      badge: true,
      des: "Peach colored softmaterial 80% cotton fabric. Women's Stylish Overcoat best for winters.",
    },
    {
      _id: 1002,
      img: spf7,
      productName: "Deepneck Top",
      shop:"Muklava",
      price: "2180",
      mprice: "4435",
      off: "51",
      review: "145",
      color: "Pink",
      badge: true,
      des: "Classic deepneck top. Cloth material fleece, A ready to go partywear.",
    },
    {
      _id: 1003,
      img: spf8,
      productName: "Windbreaker",
      price: "1125",
      mprice: "1435",
      off: "22",
      review: "125",
      shop:"Muklava",
      color: "Grey",
      badge: true,
      des: "Parachuite material windbreaker for women style it with office formals as well as casual wear.",
    },
    {
      _id: 1004,
      img: spf9,
      productName: "Trouser",
      shop:"Muklava",
      price: "1220",
      mprice: "1435",
      off: "15",
      review: "135",
      color: "Off White",
      badge: true,
      des: "70% cotton mens trouser. A ready to go with formals as well as casuals.",
    },
    {
      _id: 1005,
      img: spf10,
      productName: "Ripped Jeans",
      price: "1435",
      mprice: "5435",
      off: "74",
      review: "1451",
      color: "Black",
      badge: true,
      shop:"Muklava",

      des: "Stylish ripped denim jeans for womwn. Handy with tops and kurtis.",
    },
    {
      _id: 1006,
      img: spf11,
      productName: "Sweater",
      price: "1980",
      mprice: "4435",
      off: "55",
      review: "435",
      color: "Brown, Off White",
      shop:"Muklava",

      badge: false,
      des: "Stylish wollen knitted sweater for women. Style it with jeans or trousers.",
    },
    {
      _id: 1007,
      img: spf12,
      productName: "Oversized Jacket",
      price: "1525",
      mprice: "2435",
      off: "37",
      review: "145",
      color: "Black",
      shop:"Muklava",

      badge: true,
      des: "Stylish Jacket for women. Style it with jeans or trousers. Cloth material 40% cotton.",
    },
    {
      _id: 1008,
      img: spf13,
      productName: "Formal Coat",
      price: "1220",
      mprice: "1435",
      off: "15",
      review: "185",
      color: "Grey, White",
      shop:"Muklava",

      badge: false,
      des: "Elegant formal coat for men. Style it with jeans or trousers.",
    },
    {
      _id: 1009,
      img: spf14,
      productName: "Oversized Coat",
      price: "1244",
      mprice: "1535",
      off: "19",
      review: "135",
      color: "Blue",
      badge: true,
      shop:"Muklava",

      des: "Stylish full-length coat for women. Style it with jeans or trousers.",
    },
    {
      _id: 1010,
      img: spf16,
      productName: "Formal Blazer",
      price: "1180",
      mprice: "1435",
      off: "14",
      review: "143",
      color: "Off-White",
      badge: true,
      shop:"Muklava",
      des: "Stylish formal blazer for women. Style it with jeans or trousers.",
    },
    {
      _id: 1011,
      img: spf17,
      productName: "Gown",
      price: "1360",
      mprice: "1435",
      off: "14",
      review: "135",
      shop:"Muklava",

      color: "Blue",
      badge: false,
      des: "Stylish fleece gown for women. Style it with heels.",
    },
    {
      _id: 1012,
      img: spf18,
      productName: "Jumpsuit",
      price: "1260",
      mprice: "1435",
      off: "12",
      review: "435",
      color: "Orange",
      shop:"Muklava",

      badge: false,
      des: "Stylish jumpsuit for women. Style it with shoes.",
    },
  

    {
      _id: 1013,
      img: spf20,
      productName: "Loose Trackpants",
      price: "1460",
      mprice: "1935",
      off: "25",
      review: "135",
      color: "Grey, White, Black",
      badge: false,
      shop:"Muklava",

      des: "Stylish loose trackpants for women. Style it with sneakers and tops.",
    },
    {
      _id: 1014,
      img: spf21,
      productName: "Jumpsuit",
      shop:"Muklava",

      price: "2540",
      mprice: "2935",
      off: "30",
      review: "45",
      color: "Grey",
      badge: true,
      des: "Stylish silk jumpsuit for women. Style it with heels.",
    },
    {
      _id: 1015,
      img: spf22,
      productName: "Overcoat",
      shop:"Muklava",

      price: "2120",
      mprice: "4580",
      off: "69",
      review: "75",
      color: "Neon",
      badge: false,
      des: "Stylish overcoat for women. Style it with jeans or trousers.",
    },
    {
      _id: 1016,
      img: spfFive,
      productName: "Activ Wear",
      shop:"Muklava",

      price: "1835",
      mprice: "4435",
      off: "59",
      review: "35",
      color: "Yellow",
      badge: true,
      des: "Stylish activ wear for women. Style it with sports shoes.",
    },
  ];
  // =================== PaginationItems End here =================
  