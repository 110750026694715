import { React, useState } from "react";
import { Link } from "react-router-dom";
import cg from "../assets/svg/cg.png";
import styles from "./Cg.module.css"
const Navbar = () => {
  return (
    <>
    <div className={styles.colnav}>
    <img className={styles.cg} src={cg} alt="cg" />
     <div className={styles.it}>
        <h3>Now shop your favourite products Online at Pandri Market</h3>
        <h4>300+ Shops</h4>
        <div className={styles.tg}>
         
         
         <Link to="/muklava"><ul><li>Muklava (Open)</li></ul></Link>  
         
       
        </div>
        <h5> Opening Soon - </h5>
        <div className={styles.tg}>
         <ul>
          <li>Fashion House</li>  
          <li>Manyavar&Mohey</li>  
          <li>Payal Collection</li>  
         </ul>
         <div className={styles.tg1}></div>
         <ul>
             <li>Parag Fashion</li>
             <li>HariOm Garments</li>
             <li>Tulsi </li>
         </ul>
     </div>
     </div>
     
    </div>
    
    </>
  );
};

export default Navbar;
