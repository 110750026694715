import './carousel1.css';


const MultiItemCarousel = () => {
  
  return (
    <div  className='budget'>
          <Card  />
    </div>
  );
};

const Card = () => {
  return (
    <>
      <div style={{width:'100%',textAlign:'center'}}>
        <h2 style={{fontWeight:'700'}}>GET EXCITING OFFERS</h2>
      </div>
      </>
  );
};

export default MultiItemCarousel;
